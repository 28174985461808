<template>
  <div>
    <Navbar />

    <section class="playlists p-8 lg:p-20 flex-auto">
      <div
        class="container mx-auto flex flex-col lg:flex-row items-center justify-between mb-5 gap-2 space-y-2 lg:space-y-0"
      >
        <h1 class="text-2xl 2xl:text-4xl font-semibold">Nuova Serata</h1>

        <div class="flex items-center gap-5">
          <router-link :to="{ name: 'Sessions' }" class="btn btn--red"
            >&larr; Torna alle serate</router-link
          >
        </div>
      </div>

      <div class="container mx-auto">
        <form
          class="mb-0 flex flex-col xl:flex-row gap-10"
          id="sessionCreate"
          @submit.prevent="onSubmit"
        >
          <div class="xl:w-1/4 space-y-8">
            <div class="space-y-1">
              <label
                  for="session-name"
                  class="leading-none text-sm font-semibold"
              >Nome locale/evento:</label
              >
              <input
                  name="session-name"
                  id="session-name"
                  class="border-none bg-slate-800 rounded w-full focus:ring-2 focus:ring-sky-500 outline-none"
                  type="text"
                  v-model="sessionName"
                  @change="submittedInvalidForm = false"
              />

              <div v-if="submittedInvalidForm">
                <div v-for="error in v$.$silentErrors" :key="error">
                  <div
                      v-if="error.$property === 'name'"
                      class="form-errors bg-red-100 border border-red-200 text-xs text-red-500 mt-2 p-1 rounded-sm"
                  >
                    {{ error.$message }}
                  </div>
                </div>
              </div>
            </div>

            <div class="space-y-1">
              <label
                for="session-name"
                class="leading-none text-sm font-semibold"
                >Nome Serata:</label
              >
              <input
                name="session-name"
                id="session-name"
                class="border-none bg-slate-800 rounded w-full focus:ring-2 focus:ring-sky-500 outline-none"
                type="text"
                v-model="sessionData.name"
                @change="submittedInvalidForm = false"
                disabled
              />

              <div v-if="submittedInvalidForm">
                <div v-for="error in v$.$silentErrors" :key="error">
                  <div
                    v-if="error.$property === 'name'"
                    class="form-errors bg-red-100 border border-red-200 text-xs text-red-500 mt-2 p-1 rounded-sm"
                  >
                    {{ error.$message }}
                  </div>
                </div>
              </div>
            </div>

            <div class="space-y-1">
              <label
                for="public-name"
                class="leading-none text-sm font-semibold"
                >Nome pubblico:</label
              >
              <input
                name="public-name"
                id="public-name"
                class="border-none bg-slate-800 rounded w-full focus:ring-2 focus:ring-sky-500 outline-none"
                type="text"
                v-model="sessionData.publicName"
                @change="submittedInvalidForm = false"
                disabled
              />

              <div v-if="submittedInvalidForm">
                <div v-for="error in v$.$silentErrors" :key="error">
                  <div
                    v-if="error.$property === 'publicName'"
                    class="form-errors bg-red-100 border border-red-200 text-xs text-red-500 mt-2 p-1 rounded-sm"
                  >
                    {{ error.$message }}
                  </div>
                </div>
              </div>
            </div>

            <div class="space-y-1">
              <label
                for="session-date"
                class="leading-none text-sm font-semibold"
                >Data inizio serata:</label
              >
              <Datepicker
                v-model="sessionData.date"
                class="w-full"
              ></Datepicker>
            </div>

            <div class="space-y-1">
              <label
                for="session-date"
                class="leading-none text-sm font-semibold"
                >Data fine serata:</label
              >
              <Datepicker
                v-model="sessionData.dateEnd"
                class="w-full"
              ></Datepicker>
            </div>

            <!-- <div class="space-y-1">
              <label for="join-mode" class="leading-none text-sm font-semibold"
                >Modalità di partecipazione:</label
              >
              <select
                name="join-mode"
                id="join-mode"
                class="border-none bg-slate-800 rounded w-full focus:ring-2 focus:ring-sky-500 outline-none"
                v-model="sessionData.joinMode"
              >
                <option value="private">Registrazione obbligatoria</option>
                <option value="guests_only">Senza registrazione</option>
                <option value="public">Entrambi</option>
              </select>
            </div> -->

            <div class="space-y-1">
              <label
                for="max-pick-count"
                class="leading-none text-sm font-semibold"
                >Numero massimo di canzoni scelte per utente:</label
              >
              <input
                name="max-pick-count"
                id="max-pick-count"
                class="border-none bg-slate-800 rounded w-full focus:ring-2 focus:ring-sky-500 outline-none"
                type="number"
                min="0"
                step="1"
                v-model="sessionData.maxPickCount"
                @change="submittedInvalidForm = false"
              />
            </div>

            <div class="space-y-1">
              <label for="delay" class="leading-none text-sm font-semibold"
                >Delay scelte canzoni (in secondi):</label
              >
              <input
                name="delay"
                id="delay"
                class="border-none bg-slate-800 rounded w-full focus:ring-2 focus:ring-sky-500 outline-none"
                type="number"
                min="0"
                step="60"
                v-model="sessionData.delay"
                @change="submittedInvalidForm = false"
              />
            </div>

            <div class="space-y-1">
              <label for="cal-rate" class="leading-none text-sm font-semibold"
                >Difficoltà:</label
              >
              <select
                v-model="sessionData.calRate"
                @change="submittedInvalidForm = false"
                name="cal-rate"
                id="cal-rate"
                class="border-none bg-slate-800 rounded w-full focus:ring-2 focus:ring-sky-500 outline-none"
              >
                <option :value="150">Soft (150 cal/h)</option>
                <option :value="300">Normal (300 cal/h)</option>
                <option :value="450">High (450 cal/h)</option>
              </select>
            </div>
          </div>

          <div class="xl:flex-1 lg:mt-0">
            <h3 class="font-semibold mb-2">
              Scegli le playlist della tua serata:
            </h3>
            <div class="text-right">
              <input
                id="select-all"
                type="checkbox"
                name="select-all"
                class="mr-2"
                v-model="selectAll"
              />
              <label
                for="select-all"
                class="text-sky-500 text-xs uppercase font-semibold tracking-wide hover:text-red-500 transition duration-300"
                >Seleziona tutto</label
              >
            </div>
            <div
              class="playlists overflow-y-scroll max-h-80 w-full rounded-lg focus:ring-red-500 mb-2"
            >
              <div
                class="flex justify-between items-center px-3 py-3 border-b border-slate-900 bg-slate-800"
                v-for="playlist in playlists"
                :key="playlist.id"
              >
                <div class="flex items-center gap-2">
                  <img
                    :src="playlist.image"
                    :alt="playlist.name"
                    class="w-20 h-20"
                  />
                  <h3 class="font-semibold text-lg">{{ playlist.name }}</h3>
                </div>
                <input
                  class="w-7 h-7 border-none bg-gray-200 rounded focus-within:bg-sky-500 focus:bg-sky-500 checked:bg-sky-500"
                  type="checkbox"
                  :value="playlist.id"
                  name="session-playlist"
                  :id="playlist.id"
                  v-model="sessionPlaylists"
                />
              </div>
            </div>

            <button
              v-if="sessionPlaylists.length"
              class="disabled:opacity-50 disabled:cursor-not-allowed btn btn--red"
              v-on:click="SessionCreate"
            >
              Crea
            </button>

            <button
              v-else
              class="disabled:opacity-50 disabled:cursor-not-allowed btn btn--red"
              v-on:click="SessionCreate"
              disabled
            >
              Crea
            </button>
          </div>
        </form>

        <div
          v-if="success"
          :class="{ 'bg-green-100 border-green-200 text-green-500': success }"
          class="mt-2 p-1 border rounded-sm text-xs"
        >
          {{ success }}
        </div>
        <div
          v-if="error"
          class="mt-2 p-1 border rounded-sm text-xs bg-red-100 border-red-200 text-red-500"
        >
          {{ error }}
        </div>

        <router-link
          class="btn btn--red mt-5"
          v-if="success"
          :to="{
            name: 'Session',
            params: {
              id: sessionId,
              name: sessionData.name,
            },
          }"
          >Visualizza serata</router-link
        >
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import { getClient } from "@/includes/api";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import Datepicker from "@vuepic/vue-datepicker";

export default {
  components: { Footer, Navbar, Datepicker },
  props: ["id", "name"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    const date = new Date();
    date.setHours(18, 0, 0, 0);

    const dateEnd = new Date();
    dateEnd.setDate(dateEnd.getDate()+1);
    dateEnd.setHours(2, 0, 0, 0);

    return {
      sessionData: {
        name: '',
        publicName: '',
        delay: 0,
        maxPickCount: 100,
        joinMode: "private",
        calRate: 300,
        date: date,
        dateEnd: dateEnd,
      },
      sessionName: '',
      sessionPlaylists: [],
      playlists: [],
      submittedInvalidForm: false,
      success: "",
      formDisabled: true,
      error: null,
      sessionId: null,
      selectAll: false,
    };
  },
  async mounted() {
    try {
      console.log("Getting Playlists...");
      const apiClient = getClient();
      const playlists = await apiClient.getPlaylists();
      if (!Array.isArray(playlists)) {
        return;
      }
      this.playlists = playlists;

      this.selectAll = true;
    } catch (err) {
      console.error(err);
      this.error =
        "Attenzione, esiste già un'altra sessione con questo codice!";
    }
  },
  methods: {
    async SessionCreate() {
      try {
        if (this.v$.$invalid) {
          return;
        }
        let apiClient = getClient();
        let newSessionData = await apiClient.SessionCreate(this.sessionData);
        this.sessionId = newSessionData.id;
        console.log(this.formDisabled);
        if (
          Array.isArray(this.sessionPlaylists) &&
          this.sessionPlaylists.length > 0
        ) {
          if (newSessionData.id !== undefined) {
            let playlistsAdded = await apiClient.addPlaylistsToSession(
              newSessionData.id,
              this.sessionPlaylists
            );
            if (!playlistsAdded) {
              console.error("Playlists not added");
            } else {
              this.success = "Serata creata!";
            }
          } else {
            console.error("New session without id");
          }
        } else {
          this.success = "Serata creata!";
        }
      } catch (err) {
        console.log(err.message);
        this.error =
          "Attenzione, hai creato una serata con lo stesso nome e lo stesso codice di una già presente!";
      }
    },
    onSubmit() {
      if (this.v$.$invalid) {
        this.submittedInvalidForm = true;
        this.success = true;
      }
      return true;
    },
    genSessionNames() {
      if (
          this.sessionName.length === 0 ||
          this.sessionData.date === undefined
      ) {
        this.sessionData.name = '';
        this.sessionData.publicName = '';
        return;
      }

      const date = this.sessionData.date;
      console.log(date);
      const fullYear = date.getFullYear().toString();
      const shortYear = fullYear.slice(-2);
      const month = ('0' + (date.getMonth()+1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);

      this.sessionData.name = fullYear + month + day + ' ' + this.sessionName;
      this.sessionData.publicName = this.sessionName + ' ' + day + '-' + month + '-' + shortYear;
    },
    validateName(name) {
      return name.length > 0;
    },
  },
  watch: {
    selectAll() {
      if (this.selectAll) {
        this.sessionPlaylists = this.playlists.map((p) => p.id);
        return;
      }

      this.sessionPlaylists = [];
    },
    sessionName() {
      this.genSessionNames();
    },
    sessionData: {
      handler: function() {
        this.genSessionNames();
      },
      deep: true,
    },
  },
  validations() {
    return {
      sessionName: {
        required,
        name_validation: {
          $validator: this.validateName,
          $message:
              "Attenzione: il nome può contenere solo lettere, trattini e spazi",
        },
      },
      sessionData: {
        name: {
          required,
          name_validation: {
            $validator: this.validateName,
            $message:
              "Attenzione: il nome può contenere solo lettere, trattini e spazi",
          },
        },
        date: { required },
        dateEnd: { required },
      },
    };
  },
};
</script>

<style scoped>
button:disabled {
  cursor: not-allowed;
}
</style>
